<template>
  <div class="labor-container">
    <div>
      <el-form ref="form"
                :model="form"
                label-position="right"
                label-width="120px"
                :rules="rules"
                style="margin-top:30px;">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="姓名:"
                      prop="memberName"
                      >
              <el-input v-model="form.memberName"
                        placeholder="请输入姓名"
                        >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="性别:"
                      prop="gender"
                      >
              <el-select v-model="form.gender"
                         placeholder="请选择性别"
                         clearable
                         >
                <el-option v-for="item in genderList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="证件号:"
                      prop="certNo"
                      >
              <el-input v-model="form.certNo"
                        placeholder="请输入证件号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="工号:"
                          prop="memberNo"
                          >
              <el-input v-model="form.memberNo"
                        placeholder="请输入工号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="手机号:"
                          prop="mobile"
                          >
              <el-input v-model="form.mobile"
                        placeholder="请输入手机号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="所在项目:"
                          prop="projectId"
                          >
              <el-input v-model="form.projectId"
                        placeholder="请选择项目">
                <el-button slot="append" icon="el-icon-search" @click="selectProject"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="工种:"
                          prop="workTypeCode"
                          >
              <el-select v-model="form.workTypeCode"
                          placeholder="请选择工种"
                          clearable>
                <el-option v-for="item in workTypeList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="班组:"
                          prop="teamId"
                          >
              <el-select v-model="form.teamId"
                          placeholder="请选择班组"
                          clearable>
                <el-option v-for="item in teamList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <scg-form-btn
          @add="handleAdd"
          @search="handleSearch"
          @reset="handleReset('form')">
          <el-button type="normal" class="func-btn" icon="el-icon-upload2" @click="handleImport">导入工人信息</el-button>
          <el-button type="normal" class="func-btn" icon="el-icon-download" @click="handleExport">导出工人信息</el-button>
        </scg-form-btn>
      </el-form>
    </div>
    <div class="result">
      <div class="list">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          default-expand-all>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span><img src="../../../icons/png/icon-folder.png" v-show="node.level===1" /> {{ node.label }}</span>
            <span class="info" v-show="node.level!==1">
              ({{ data.peopleNum }}人)
              <el-button
                type="text"
                size="mini"
                class="set-top"
                @click="() => setTop(data)">
                置顶
              </el-button>
              <el-button
                type="text"
                size="mini"
                class="move-up"
                @click="() => moveUp(node, data)">
                上移
              </el-button>
              <el-button
                type="text"
                size="mini"
                class="move-down"
                @click="() => moveDown(node, data)">
                下移
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="table">
        <el-table fixed
                ref="filterTable"
                :data="tableData"
                border
                >
          <el-table-column type='index'
                            width="55"
                            label="序号"
                            >
          </el-table-column>
          <el-table-column label="姓名" prop="memberName">
          </el-table-column>
          <el-table-column label="性别" prop="gender">
            <template v-slot="scope">
              {{ scope.row.gender | sexFormat}}
            </template>
          </el-table-column>
          <el-table-column label="证件号" prop="certNo" width="175px" />
          <el-table-column label="所属组织" prop="companyName">
          </el-table-column>
          <el-table-column label="手机号码" prop="mobile" />
          <el-table-column label="录入时间" prop="createTime">
          </el-table-column>
          <el-table-column label="工种" prop="workTypeName">
          </el-table-column>
          <el-table-column label="所属项目" prop="projectName" />
          <el-table-column label="当前所在区域" prop="partitionName" />
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button class="t-btn" type="text" size="small" @click="handleDetail(scope.row)"><i class="icon-xiangqingcopy detail" /> 详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <scg-pagination :pagination="pagination" @currentPageChange="getCurrentPage" @handleSizeChange="getCurrentPageSize"></scg-pagination>
      </div>
    </div>
    <scg-project-dialog
      :showDialog="showProjectDialog"
      @close="hiddenDialog" />
  </div>
</template>
<script>
import ScgPagination from '@/components/Scg-pagination'
import ScgFormBtn from '@/components/Scg-form-btn'
import ScgProjectDialog from '@/components/Scg-project-dialog'
export default {
  name: 'Labor',
  components: {
    ScgPagination,
    ScgFormBtn,
    ScgProjectDialog
  },
  data(){
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      form: {
        corpName:'', // 企业名称
        linkman:'', // 联系人姓名
        linkTel:'', // 联系电话
        province:'', // 所在省份
        city:'', // 所在城市
      },
      rules: {},
      genderList: [{
        label:'男',
        value:'1'
      },{
        label:'女',
        value:'2'
      }],
      workTypeList:[],
      teamList:[],
      showProjectDialog: false,
      treeData:[{
        id: 1,
        label: '劳务单位列表',
        peopleNum: 500,
        children: [{
          id: 2,
          label: '劳务单位1',
          peopleNum: 120
        }, {
          id: 3,
          label: '劳务单位2',
          disabled: true,
          peopleNum: 150
        }, {
          id: 4,
          label: '劳务单位4',
          disabled: true,
          peopleNum: 130
        }, {
          id: 5,
          label: '劳务单位5',
          disabled: true,
          peopleNum: 100
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods:{
    /**
     * 上移
     */
    moveUp(){
      console.log('setTop~')
    },
    /**
     * 下移
     */
    moveDown(){
      console.log('setTop~')
    },
    /**
     * 置顶
     */
    setTop(){
      console.log('setTop~')
    },
    handleNodeClick(){
      console.log('handleNodeClick~')
    },
    /**
     * 导出工人信息
     */
    handleExport(){
      console.log('handleExport~')
    },

    /**
     * 导入工人信息
     */
    handleImport(){
      console.log('handleImport~')
    },

    hiddenDialog(){
      this.showProjectDialog = false
    },
    /**
     * 选择项目
     */
    selectProject(){
      console.log('selectProject')
      this.showProjectDialog = true
    },

    /**
     * 新增
     */
    handleAdd(){
      console.log('handleAdd~')
    },

    /**
     * 重置
     */
    handleReset(formName){
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 查询
     */
    handleSearch(){
      console.log(this.form)
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
      this.pagination.currentPage = 1
      this.pagination.pageSize = val
      this.getCurrentPage()
    },
    
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.initData()
    },

    /**
     * 获取列表信息
     */
    getDataList(){
      this.tableData=[{
        memberNo:'123',
        memberName:'张三',
        gender:1,
        certNo:'310230198111134205',
        companyName:'劳务单位1',
        mobile:'13615240098',
        createTime:'2020-12-25',
        workTypeName:'木工',
        projectName:'项目1',
        partitionName:'施工区'
      },{
        memberNo:'124',
        memberName:'李四',
        gender:1,
        certNo:'310230198111134205',
        companyName:'劳务单位1',
        mobile:'13615240098',
        createTime:'2020-12-25',
        workTypeName:'木工',
        projectName:'项目1',
        partitionName:'施工区'
      },{
        memberNo:'125',
        memberName:'张三',
        gender:1,
        certNo:'310230198111134205',
        companyName:'劳务单位1',
        mobile:'13615240098',
        createTime:'2020-12-25',
        workTypeName:'木工',
        projectName:'项目1',
        partitionName:'施工区'
      },{
        memberNo:'126',
        memberName:'张三',
        gender:1,
        certNo:'310230198111134205',
        companyName:'劳务单位1',
        mobile:'13615240098',
        createTime:'2020-12-25',
        workTypeName:'木工',
        projectName:'项目1',
        partitionName:'施工区'
      },{
        memberNo:'127',
        memberName:'张三',
        gender:1,
        certNo:'310230198111134205',
        companyName:'劳务单位1',
        mobile:'13615240098',
        createTime:'2020-12-25',
        workTypeName:'木工',
        projectName:'项目1',
        partitionName:'施工区'
      }]
    },

    /**
     * 初始化信息
     */
    initData(){
      this.getDataList()
    }
  },
  mounted(){
    this.initData()
  }
}
</script>
<style lang="stylus" scoped>
.labor-container
  background-color #fff
  margin 24px
  padding 10px
  
  .result
    display flex
    border-top solid 1px #F0F2F5
    .list
      width 277px
      margin-top 37px
      
    .table
      flex 1
      padding-top 24px
      padding-left 24px
      border-left solid 1px #F0F2F5
  .custom-tree-node
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .info
      font-size 12px
      .set-top
        color #377CED
        margin-left 12px
      .move-up
        color #7CB305
      .move-down
        color #FA8C16
</style>
<style lang="stylus">
// .labor-container
  
</style>