<template>
  <el-dialog
    :visible.sync="visible"
    width="50%"
    :before-close="handleClose"
    class="scg-project-dialog-container">
    <div>
      <el-form :model="form"
        label-position="right"
        label-width="120px"
        :rules="rules"
        >
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
            <el-form-item label="项目名称:"
                      prop="projectName"
                      >
              <el-input v-model="form.projectName"
                        placeholder="请输入项目名称"
                        >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
            <el-form-item label="项目经理:"
                      prop="projectLeader"
                      >
              <el-input v-model="form.projectLeader"
                        placeholder="请输入项目经理"
                        >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
            <el-button type="primary" @click="handleSearch" style="margin-left:10px">查询</el-button>
          </el-col>
        </el-row>
        
      </el-form>
    </div>
    <el-table :data="tableData">
      <el-table-column width="55">
        <template slot-scope="scope">
          <el-radio @change.native="getCurrentRow(scope.$index)" :label="scope.$index" v-model="radio" class="textRadio">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="projectCode" label="项目编号" align="center"></el-table-column>
      <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
      <el-table-column prop="projectLeader" label="项目经理" align="center"></el-table-column>
    </el-table>
    <scg-pagination :pagination="pagination" @currentPageChange="getCurrentPage" @handleSizeChange="getCurrentPageSize"></scg-pagination>
  </el-dialog>
</template>
<script>
import ScgPagination from '@/components/Scg-pagination'
export default {
  name: "Scg-project-dialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {ScgPagination},
  data(){
    return {
      tableData: [],
      visible: this.showDialog,
      form:{},
      rules:{},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      radio: 0,
    }
  },
  methods: {
    getCurrentRow(index){
      this.radio = index
      console.log(this.radio)
    },
    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
        this.pagination.currentPage = 1
        this.pagination.pageSize = val
        this.getCurrentPage()
      },
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.getDataList()
    },
    getDataList(){
      this.tableData = [{
        projectId:'001',
        projectCode:'202101030001',
        projectName:'项目1',
        projectLeader:'经理1'
      },{
        projectId:'002',
        projectCode:'202101030002',
        projectName:'项目2',
        projectLeader:'经理2'
      },{
        projectId:'003',
        projectCode:'202101030003',
        projectName:'项目3',
        projectLeader:'经理3'
      },{
        projectId:'004',
        projectCode:'202101030004',
        projectName:'项目4',
        projectLeader:'经理4'
      },{
        projectId:'005',
        projectCode:'202101030005',
        projectName:'项目5',
        projectLeader:'经理5'
      }]
    },
    handleSearch(){
      console.log('查询~')
      this.pagination.currentPage = 1
      this.getDataList()
    },
    handleClose(){
      this.visible = false
      this.$emit('close')
    }
  },
  watch: {
    showDialog() {
      this.visible = this.showDialog
    },
    visible(val, oldVal) {
      if (!val) {
        this.handleClose()
      }else{
        this.getDataList()
      }
    }
  }
}
</script>
