<template>
  <div class="grid-container" :style="{minHeight:minHeight+'px'}">
    <div class="title" v-if="hideTitle==false">{{title}}<span class="desc">{{desc}}</span></div>
    <el-divider v-if="hasDivider" class="divider"></el-divider>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'grid-container',
  props:{
    title:{
      type: String,
      default: '标题'
    },
    desc:{
      type: String,
      default: ''
    },
    hideTitle:{
      type: Boolean,
      default: false
    },
    minHeight:{
      type: String,
      default: '355'
    },
    hasDivider:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="stylus" scoped>
.grid-container
  background #fff
  // min-height 355px
  padding 20px 0 0
  box-sizing border-box
  .divider
    margin-top 11px
    margin-bottom 20px
  .title
    font-size 16px
    color #333
    font-weight bold
    padding 0 24px
    .desc
      font-size 14px
      padding-left 50px
</style>