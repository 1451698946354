<template>
  <div class="count-container">
    <div style="text-align:right"><el-checkbox v-model="checkMe" @change="handleChecked">只看本单位</el-checkbox></div>
    <el-row :gutter="24">
      <el-col :span="12">
        <grid-container title="在建项目">
          <div id="project-building-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
      <el-col :span="12">
        <grid-container title="今日出勤人数">
          <div id="today-attendance-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <grid-container hideTitle>
          <el-table :data="companyList">
            <el-table-column prop="companyName" label="参建单位" align="center"></el-table-column>
            <el-table-column prop="rosterWorkerNum" label="注册人数" align="center"></el-table-column>
            <el-table-column prop="onSiteWorkerNum" label="在场人数" align="center"></el-table-column>
          </el-table>
          <div>更多</div>
        </grid-container>
      </el-col>
      <el-col :span="8">
        <grid-container hideTitle>
          <el-table :data="workTypeList">
            <el-table-column prop="workTypeName" label="工种" align="center"></el-table-column>
            <el-table-column prop="rosterWorkerNum" label="注册人数" align="center"></el-table-column>
            <el-table-column prop="onSiteWorkerNum" label="在场人数" align="center"></el-table-column>
          </el-table>
          <div>更多</div>
        </grid-container>
      </el-col>
      <el-col :span="8">
        <grid-container hideTitle>
          <el-table :data="nationList">
            <el-table-column prop="nationName" label="籍贯" align="center"></el-table-column>
            <el-table-column prop="rosterWorkerNum" label="注册人数" align="center"></el-table-column>
            <el-table-column prop="onSiteWorkerNum" label="在场人数" align="center"></el-table-column>
          </el-table>
          <div>更多</div>
        </grid-container>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <grid-container title="年龄">
          <el-row :gutter="24">
            <el-col :span="12">
              <div id="age-attendance-chart" style="width: 100%;height:311px;"></div>
            </el-col>
            <el-col :span="12">
              <div id="age-onduty-chart" style="width: 100%;height:311px;"></div>
            </el-col>
          </el-row>
        </grid-container>
      </el-col>
      <el-col :span="12">
        <grid-container title="注册劳务人数" desc="800人">
          <div id="roster-labor-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import GridContainer from '@/components/Grid-container'
export default {
  name: 'Attendance',
  components: {
    GridContainer
  },
  data(){
    return {
      myProjectBuildingChart:null,
      myTodayAttendanceChart:null,
      myAgeRosterChart:null,
      myAgeOnDutyChart:null,
      myRosterLaborChart:null,
      companyList:[], // 参建单位
      workTypeList:[], // 工种
      nationList:[], // 籍贯
      checkMe: false,
      companyId: '', // 当前公司ID
    }
  },
  methods:{
    handleChecked(){
      console.log(this.form)
      this.companyId = this.checkMe ? localStorage.getItem('companyId') : ''
      this.getData()
    },
    /**
     * 获取籍贯列表
     */
    getNationList(){
      this.nationList=[{
        nationId:'001',
        nationName:'湖南省',
        rosterWorkerNum:120,
        onSiteWorkerNum:110
      },{
        nationId:'002',
        nationName:'江苏省',
        rosterWorkerNum:100,
        onSiteWorkerNum:96
      },{
        nationId:'003',
        nationName:'浙江省',
        rosterWorkerNum:100,
        onSiteWorkerNum:85
      },{
        nationId:'004',
        nationName:'黑龙江省',
        rosterWorkerNum:85,
        onSiteWorkerNum:78
      },{
        nationId:'005',
        nationName:'河南省',
        rosterWorkerNum:90,
        onSiteWorkerNum:75
      },]
    },
    /**
     * 获取工种列表
     */
    getWorkTypeList(){
      this.workTypeList=[{
        workTypeId:'001',
        workTypeName:'木工',
        rosterWorkerNum:120,
        onSiteWorkerNum:110
      },{
        workTypeId:'002',
        workTypeName:'泥水工',
        rosterWorkerNum:100,
        onSiteWorkerNum:96
      },{
        workTypeId:'003',
        workTypeName:'电工',
        rosterWorkerNum:100,
        onSiteWorkerNum:85
      },{
        workTypeId:'004',
        workTypeName:'油漆工',
        rosterWorkerNum:85,
        onSiteWorkerNum:78
      },{
        workTypeId:'005',
        workTypeName:'架子工（普通架子工）',
        rosterWorkerNum:90,
        onSiteWorkerNum:75
      },]
    },
    /**
     * 获取参建单位列表
     */
    getCompanyList(){
      this.companyList=[{
        companyId:'001',
        companyName:'参建单位1',
        rosterWorkerNum:120,
        onSiteWorkerNum:110
      },{
        companyId:'002',
        companyName:'参建单位2',
        rosterWorkerNum:100,
        onSiteWorkerNum:96
      },{
        companyId:'003',
        companyName:'参建单位3',
        rosterWorkerNum:100,
        onSiteWorkerNum:85
      },{
        companyId:'004',
        companyName:'参建单位4',
        rosterWorkerNum:85,
        onSiteWorkerNum:78
      },{
        companyId:'005',
        companyName:'参建单位5',
        rosterWorkerNum:90,
        onSiteWorkerNum:75
      },]
    },
    drawTodayAttendanceChart(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myTodayAttendanceChart = this.$echarts.init(document.getElementById('today-attendance-chart'));
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {
            // trigger: 'axis',
            // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            //   type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            // }
          },
          legend: {
              data:['在场','未进场']
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["一建","二建","三建","四建","五建","六建","七建","八建","九建","十建","十一建","十二建","十三建"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '在场',       
            type: 'bar',
            stack: "今日出勤人数",//折叠显示
            color:'#5b9bd5',
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'inside',       //  位置设为top
                  formatter: '{c}',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            itemStyle: {
                normal: {
                    color: 'rgba(123, 223, 182, 1)'   // 设置柱背景颜色
                }
            },
            data: [22, 40, 72, 20, 20, 40, 10, 40, 72, 20, 20, 40, 25],
          },{                               //新的一个柱子 注意不设stack
            name: '未进场',       
            type: 'bar',
            stack: "今日出勤人数",//折叠显示
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'top',       //  位置设为top
                  formatter: '{c}',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            itemStyle: {
                normal: {
                    color: 'rgba(206, 212, 210, 1)'   // 设置柱背景颜色
                }
            },
            data: [22, 40, 72, 20, 20, 40, 10, 40, 72, 20, 20, 40, 25],
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myTodayAttendanceChart.setOption(option);
    },
    /**
     * 在建项目统计
     */
    drawProjectBuildingChart(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myProjectBuildingChart = this.$echarts.init(document.getElementById('project-building-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {},
          legend: {
              data:['在建','其他']
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["一建","二建","三建","四建","五建","六建","七建","八建","九建","十建","十一建","十二建","十三建"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '在建',       
            type: 'bar',  
            stack: "项目",//折叠显示
            data: [360, 340, 336, 310, 310, 220, 205, 200, 136, 110, 110, 100, 90],
            color:'#5b9bd5',
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'inside',       //  位置设为top
                  formatter: '{c}',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            emphasis: {
                focus: 'series'
            },
          },{
              name: '其他',
              type: 'bar',
              stack: "项目",//折叠显示
              data: [80, 70, 66, 60, 60, 60, 65, 60, 66, 60, 60, 60,50],
              color:"#DFEAF5",
              label: {                 
                normal: {
                    show: true, //显示数值
                    position: 'inside',       //  位置设为top
                    formatter: '{c}',
                    textStyle: { color: '#000' } //设置数值颜色
                }
              },
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myProjectBuildingChart.setOption(option);
    },
    /**
     * 注册劳务人数统计
     */
    drawRosterLaborChart(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myRosterLaborChart = this.$echarts.init(document.getElementById('roster-labor-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {},
          legend: {},
          grid: {
            containLabel: true
          },
          xAxis: {
            
          },
          yAxis: {
            data: ["劳务工人库","项目上花名册人数","项目进场人数"],
          },
          series: [{                               //新的一个柱子 注意不设stack
            name: '',       
            type: 'bar',  
            data: [800, 750, 650],
            color:'#5b9bd5',
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'inside',       //  位置设为top
                  formatter: '{c}',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            emphasis: {
                focus: 'series'
            },
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myRosterLaborChart.setOption(option);
    },
    /**
     * 年龄：注册人数统计
     */
    drawAgeRosterChart(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myAgeRosterChart = this.$echarts.init(document.getElementById('age-attendance-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: '注册人数',
              bottom: 0,
              x:'center',
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            data: ['18-25', '25-35', '35-45', '45-55', '55以上']
          },
          grid: {
            containLabel: true
          },
          series: [{                               //新的一个柱子 注意不设stack
            name: '',       
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            // label: {
            //     position: 'inner',
            //     fontSize: 14,
            // },
            labelLine: {
                show: false
            }, 
            data: [
              {value: 1548, name: '男'},
              {value: 775, name: '女', selected: true}
            ],
            // color:'#5b9bd5',
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'inside',       //  位置设为top
                  formatter: '{b}\n{d}%',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            emphasis: {
                focus: 'series'
            },
          },{                               //新的一个柱子 注意不设stack
            name: '',       
            type: 'pie',
            radius: ['45%', '60%'],
            labelLine: {
                length: 30,
            },
            data: [
              {value: 300, name: '18-25'},
              {value: 335, name: '25-35'},
              {value: 310, name: '35-45'},
              {value: 251, name: '45-55'},
              {value: 50, name: '55以上'}
            ],
            // color:'#5b9bd5',
            
            emphasis: {
                focus: 'series'
            },
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myAgeRosterChart.setOption(option);
    },
    /**
     * 年龄：在岗人数统计
     */
    drawAgeOnDutyChart(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myAgeOnDutyChart = this.$echarts.init(document.getElementById('age-onduty-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: '在岗人数',
              bottom: 0,
              x:'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            data: ['18-25', '25-35', '35-45', '45-55', '55以上']
          },
          grid: {
            containLabel: true
          },
          series: [{                               //新的一个柱子 注意不设stack
            name: '',       
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            // label: {
            //     position: 'inner',
            //     fontSize: 14,
            // },
            labelLine: {
                show: false
            }, 
            data: [
              {value: 1200, name: '男'},
              {value: 900, name: '女', selected: true}
            ],
            // color:'#5b9bd5',
            label: {                 
              normal: {
                  show: true, //显示数值
                  position: 'inside',       //  位置设为top
                  formatter: '{b}\n{d}%',
                  textStyle: { color: '#000' } //设置数值颜色
              }
            },
            emphasis: {
                focus: 'series'
            },
          },{                               //新的一个柱子 注意不设stack
            name: '',       
            type: 'pie',
            radius: ['45%', '60%'],
            labelLine: {
                length: 30,
            },
            data: [
              {value: 1048, name: '18-25'},
              {value: 335, name: '25-35'},
              {value: 310, name: '35-45'},
              {value: 251, name: '45-55'},
              {value: 234, name: '55以上'}
            ],
            // color:'#5b9bd5',
            
            emphasis: {
                focus: 'series'
            },
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myAgeOnDutyChart.setOption(option);
    },
    getData(){
      this.drawProjectBuildingChart()
      this.drawTodayAttendanceChart()
      this.drawRosterLaborChart()
      this.drawAgeRosterChart()
      this.drawAgeOnDutyChart()
      this.getNationList()
      this.getCompanyList()
      this.getWorkTypeList()
    }
  },
  mounted(){
    this.getData()
  }
}
</script>
<style lang="stylus" scoped>
.count-container
  margin 24px
  padding 10px
  .post-table
    padding 20px 24px
    .th
      color rgba(0, 0, 0, 0.85)
      font-size 14px
    .td
      color rgba(0, 0, 0, 0.85)
      font-size 16px
    .center
      text-align center
    .num
      font-size 30px
    

  .el-row
    margin-bottom 24px
    &:last-child
      margin-bottom 0
    

</style>